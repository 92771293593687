import { Loader } from "@googlemaps/js-api-loader";

const loaderInstance = new Loader({
    apiKey: "AIzaSyDtSCVEZsZzdPCXCG7Vx1Ein4OAH5aCDjc", 
    version: "weekly",
    libraries: ["places,geometry,visualization,drawing,streetView"], 
});

let googleApiPromise;

export const loadGoogleApi = () => {
    if (!googleApiPromise) {
        googleApiPromise = loaderInstance.load();
    }
    return googleApiPromise;
};