import Vue from 'vue'
import App from './App.vue'
import i18n from "./i18n";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css';


Vue.config.productionTip = false

Vue.use(VueSweetalert2);

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
