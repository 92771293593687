<template>
    <span class="route-storage-wrapper">
        <transition name="fade">
            <div v-if="isVisible && paginatedRoutes.length > 0">
                <div class="list-group storage" v-for="(route, index) in paginatedRoutes" :key="index">
                    <span
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
                        <span class="editable-title" @dblclick="startEditing(index)">
                            <input v-if="route.isEditing" ref="routeInputs" @keyup.enter="stopEditing(index)"
                                v-model="route.title" @blur="stopEditing(index)" class="title-input" />
                            <span v-else>{{ route.title || 'Unnamed Route' }}</span>
                        </span>
                        <span @click="onSelectedRoute(index)" class="route-details">
                            <div class="origin"><span class="truncate-text"> From: {{ route.origin.label }}</span></div>
                            <div class="destination"><span class="truncate-text">To: {{ route.destination.label
                                    }}</span></div>
                        </span>
                        <button @click="startEditing(index)" @keyup.enter="stopEditing(index)"
                            class="bi bi-pen edit-icon" :style="{ backgroundColor: customStyle.backgroundColor }">
                        </button>
                        <button @click="removeRoute(index)" class="bi-trash remove-icon" :style="{ backgroundColor: customStyle.backgroundColor }"></button>
                    </span>
                </div>
            </div>
            <div v-if="paginatedRoutes.length === 0">
                <div class="list-group storage d-flex align-items-center justify-content-center bg-white rounded"
                    style="min-height: 150px;">
                    <span class="text-muted">{{ $t('no_route_saved') }}</span>
                </div>
            </div>
        </transition>
        <div v-show="routes.length > 3" class="pagination-controls">
            <button v-if="paginatedRoutes.length > 0" @click="changeRoutes('Previous')" :disabled="currentIndex === 0"
                class="bi bi-caret-left previous-icon"></button>
            <button v-if="paginatedRoutes.length > 0" @click="changeRoutes('Next')"
                :disabled="currentIndex + maxRoute >= routes.length" class="bi bi-caret-right next-icon"></button>
        </div>
    </span>
</template>


<script>
export default {
    emits: ['onSelectedRoute'],
    props: {
        customStyle: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            routes: [],
            paginatedRoutes: [],
            currentEditingIndex: 0,
            currentIndex: 0,
            maxRoute: 3,
            isVisible: true,
        };
    },
    mounted() {
        this.loadRoutes();
    },
    methods: {
        onSelectedRoute(index) {
            this.$emit('onSelectedRoute', this.paginatedRoutes[index]);
        },
        loadRoutes() {
            const savedRoutes = localStorage.getItem("savedRoutes");
            if (savedRoutes) {
                this.routes = JSON.parse(savedRoutes).map((route, index) => ({
                    ...route,
                    title: route.title || `Route ${index + 1}`,
                    isEditing: false
                }));
                this.routePagination();
            }
        },
        routePagination() {
            this.paginatedRoutes = [];
            for (let i = this.currentIndex; i < this.currentIndex + this.maxRoute; i++) {
                if (this.routes[i]) {
                    this.paginatedRoutes.push(this.routes[i]);
                }
            }

            if(this.paginatedRoutes.length === 0 && this.currentIndex > 0) {
                this.currentIndex -= this.maxRoute;
                this.routePagination();
            }
        },
        changeRoutes(direction) {
            this.isVisible = false;
            setTimeout(() => {
                if (direction === "Next" && this.currentIndex + this.maxRoute < this.routes.length) {
                    this.currentIndex += this.maxRoute;
                } else if (direction === "Previous" && this.currentIndex >= this.maxRoute) {
                    this.currentIndex -= this.maxRoute;
                }
                this.routePagination();
                this.isVisible = true;
            }, 400);
        },
        startEditing(index) {
            // Close any other currently editing input
            if (this.currentEditingIndex !== null) {
                this.stopEditing(this.currentEditingIndex);
            }

            // Set the current route as editing
            this.paginatedRoutes[index].isEditing = true;
            this.currentEditingIndex = index;

            // Select the input content
            this.$nextTick(() => {
                const input = this.$refs.routeInputs[index];
                if (input) input.select();
            });
        },
        stopEditing(index) {
            const route = this.paginatedRoutes[index];
            route.isEditing = false;
            this.currentEditingIndex = null;
            this.saveRoutes();
        },
        removeRoute(index) {
            const globalIndex = this.currentIndex + index;
            this.routes.splice(globalIndex, 1);
            this.renameRoutes(this.routes);
            this.saveRoutes();
            if(this.paginatedRoutes.length === 1 && this.currentIndex >= this.maxRoute) this.currentIndex -= this.maxRoute;
            this.routePagination();
        },
        saveRoutes() {
            localStorage.setItem("savedRoutes", JSON.stringify(this.routes));
        },
        handleClickOutside(event) {
            if (this.currentEditingIndex !== null) {
                const input = this.$refs.routeInputs[this.currentEditingIndex];
                if (input && !input.contains(event.target)) {
                    this.stopEditing(this.currentEditingIndex);
                }
            }
        },
        renameRoutes(routes) {
            let counter = 1;
            routes.forEach(route => {
                if (!route.title || /^Route \d+$/.test(route.title)) {
                    route.title = `Route ${counter}`;
                    counter++;
                }
            })
        }
    }
};
</script>

<style scoped>
.route-storage-wrapper {
    padding-top: 16px;
}

.storage {
    width: 100%;
    margin-bottom: 16px;
}

.editable-title {
    flex: 1;
    cursor: pointer;
}

.editable-title .title-input {
    width: 60%;
    font-size: inherit;
    padding: 2px 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.route-details {
    flex: 3;
    text-align: left;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0.7;
}

.transition-opacity {
    transition: opacity 0.5s ease-out;
}

.previous-icon,
.next-icon,
.edit-icon,
.remove-icon {
    border: none;
    display: flex;
    align-items: center;
    font-size: 15px;
    text-align: center;
    width: 5px;
    height: 1.8rem;
    cursor: pointer;
}

.previous-icon,
.next-icon {
    background-color: transparent;
}

.edit-icon,
.remove-icon {
    color: white;
}

.remove-icon {
    margin-left: 8px;
    margin-right: -8px;
}

.edit-icon {
    padding-right: 30px;
}

.previous-icon {
    font-size: xx-large;
    margin-left: 100px;
}

.next-icon {
    font-size: xx-large;
    margin-right: 100px;
}

.truncate-text {
    display: inline-block;
    width: 18ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>