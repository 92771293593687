<template>
  <div id="app">
    <yurekai-neo-map-base-search marker-clicked="getInfoWindowContent" apikey="AIzaSyDtSCVEZsZzdPCXCG7Vx1Ein4OAH5aCDjc"
      version="weekly" libraries="places,geometry,visualization,drawing,streetView" agentid="123456" lang="us">
    </yurekai-neo-map-base-search>
  </div>
</template>

<script>

import YurekaiNeoMapBaseSearch from './components/YurekaiNeoMapBaseSearch.vue';
import { loadGoogleApi } from './googleLoader';

export default {
  name: 'App',
  components: {
    YurekaiNeoMapBaseSearch
  },
  created() {
    loadGoogleApi().then(() => {
    }).catch((error) => {
      console.error('Error loading Google API', error);
    });
  },
}
</script>

<style>
.pac-container {
  z-index: 1058 !important;
}

.gm-style .gm-style-iw-d .gm-style-iw-c {
  margin: 0 !important;
  height: 100% !important;
  overflow: hidden !important;
  max-width: 400px !important;
  max-height: 400px !important;
  width: 300px !important;
  background-color: white !important;
  max-height: none !important;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.gm-style .gm-style-iw-c {
  padding: 12px !important;
  border-radius: 8px !important;
  max-height: none !important;
  /* height: 200px;
  width: 300px; */
}


/*  Level 4 Media Query: check if pointer device has limited accuracy like touchscreen */
@media (pointer:coarse) {
  .gm-style .gm-style-iw-c {
    height: 200px;
    width: 300px;
  }
}

.gm-style-iw-chr .button-container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 10px !important;
  /* height: 20% !important; */
}

.gm-style-iw-chr {
  height: 20% !important;
}

.gm-ui-hover-effect {
  bottom: 12px !important;
  left: 10px !important;
  z-index: 9999 !important;
}

.gm-ui-hover-effect>span {
  width: 22px !important;
  height: 22px !important;
  margin: 12px !important;
  top: 7px !important;
}

.gm-style>div {
  z-index: 1;
}

.info-button-container {
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: -37px !important;
  margin-right: 40px !important;
  gap: 30% !important;
}

.button-icon-container {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  background: transparent !important;
  width: auto !important;
  height: auto !important;
}

.bi-x-lg::before {
    content: "\f659";
    margin-top: 1.5rem;
}

.btn-icon i {
  font-size: 1.5rem !important;
  margin: 0 !important;
}

.bi-x-lg {
  color: black !important;
}
/* html, body {
  overscroll-behavior: none;
} */
/* html, body {
  overflow: hidden;
  height: 100%;
  touch-action: none; 
  overscroll-behavior: none; 
}


#app { 
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */
</style>
